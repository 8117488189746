import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './styles.scss';
import { API_BASE_URL } from './config';

const Profile = ({ authToken }) => {
    const [profile, setProfile] = useState({ username: '', interests: [], profilePicture: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/user/profile`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                setProfile(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching profile:', err);
                setError('Failed to fetch profile');
                setLoading(false);
            }
        };

        fetchProfile();
    }, [authToken]);

    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('profilePic', selectedFile);

        try {
            const response = await axios.post(`${API_BASE_URL}/api/user/upload-profile-pic`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
            });
            setMessage(response.data.message);
            setProfile({ ...profile, profilePicture: response.data.filePath });
        } catch (err) {
            setMessage('Failed to upload the image');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="profile-container">
            <div className="profile-left">
                {profile.profilePicture ? (
                    <div className="profile-picture-wrapper">
                        <img
                            src={`${API_BASE_URL}${profile.profilePicture}`}
                            alt="Profile"
                            className="profile-picture"
                        />
                        <label htmlFor="upload-photo" className="change-profile-btn">
                            Change Profile Picture
                        </label>
                        <input type="file" id="upload-photo" onChange={handleFileChange} />
                    </div>
                ) : (
                    <div className="profile-picture-wrapper">
                        <p>No profile picture uploaded.</p>
                        <label htmlFor="upload-photo" className="upload-btn">
                            Upload Profile Picture
                        </label>
                        <input type="file" id="upload-photo" onChange={handleFileChange} />
                    </div>
                )}
                {message && <p className="message">{message}</p>}
            </div>
            <div className="profile-right">
                <h1 className="username">{profile.username}</h1>
                <div className="interests-section">
                    <h2>Interests:</h2>
                    <ul className="interests-list">
                        {profile.interests.map((interest) => (
                            <li key={interest}>{interest}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Profile;
