import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import './styles.scss';
import { API_BASE_URL } from './config';

const Chats = ({ authToken }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const chatWindowRef = useRef(null);

    useEffect(() => {
        const fetchChatHistory = async () => {
            try {
                const res = await axios.get(`${API_BASE_URL}/api/chat/history`, {
                    headers: { Authorization: `Bearer ${authToken}` }
                });

                if (res.data.length === 0) {
                    const greetingRes = await axios.post(`${API_BASE_URL}/api/chat/generate-greeting`, {}, {
                        headers: { Authorization: `Bearer ${authToken}` }
                    });
                    const greetingMessage = { sender: 'AI', content: greetingRes.data.greeting };
                    setMessages([greetingMessage]);

                } else {
                    setMessages(res.data);
                }
            } catch (err) {
                setError('Error fetching chat history');
            } finally {
                setLoading(false);
            }
        };

        fetchChatHistory();
    }, [authToken]);

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTo({
                top: chatWindowRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [messages]);

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;

        const userMessage = { sender: 'user', content: newMessage.trim() };
        setMessages(prev => [...prev, userMessage]);
        setNewMessage('');
        setIsTyping(true);

        try {
            const res = await axios.post(`${API_BASE_URL}/api/chat/message`, { messageContent: newMessage }, {
                headers: { Authorization: `Bearer ${authToken}` }
            });
            const aiMessage = { sender: 'AI', content: res.data.answer };
            setMessages(prev => [...prev, aiMessage]);
        } catch (err) {
            setError('Error sending message');
        } finally {
            setIsTyping(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    if (loading) return <p className="centered">Preparing your personalized model...</p>;
    if (error) return <p className="error">{error}</p>;

    return (
        <div className="chat-container">
            <h1>Chat</h1>
            <div className="chat-window" ref={chatWindowRef}>
                {messages.map((msg, index) => (
                    <div key={index} className={`chat-message ${msg.sender === 'AI' ? 'ai-message' : 'user-message'}`}>
                        <p className="chat-sender">{msg.sender === 'AI' ? 'AI' : 'You'}:</p>
                        <div className="chat-content">
                            <ReactMarkdown>{msg.content}</ReactMarkdown>
                        </div>
                    </div>
                ))}
                {isTyping && (
                    <div className="chat-message ai-message">
                        <p className="chat-sender">AI:</p>
                        <p className="chat-content">typing...</p>
                    </div>
                )}
            </div>
            <div className="chat-input">
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Type your message here..."
                />
                <button onClick={handleSendMessage} disabled={isTyping} className={isTyping ? 'disabled' : ''}>Send</button>
            </div>
        </div>
    );
};

export default Chats;
