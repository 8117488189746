import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles.scss'; // Ensure the styles are imported

const Navbar = ({ authToken, setAuthToken, username }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        setAuthToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        navigate('/auth');
    };

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <Link to="/">LearnMate</Link>
                <span className="slogan">Your Personalized Learning Companion</span>
            </div>
            <div className="navbar-links">
                {authToken ? (
                    <>
                        <Link to="/profile">My Profile</Link>
                        <Link to="/chats">Chat</Link>
                        <Link to="/auth" onClick={handleLogout}>Logout</Link>
                    </>
                ) : (
                    <Link to="/auth">Login</Link>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
