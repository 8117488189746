import './App.css';
import './styles.scss'; // Import the CSS file
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Auth from './Auth';
import Interests from './Interests';
import Navbar from './Navbar';
import Profile from './Profile';
import Homepage from './Homepage'; // Import the new Homepage component
import Chats from './Chats';

const App = () => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('token'));
    const [username, setUsername] = useState('');

    useEffect(() => {
        setAuthToken(localStorage.getItem('token'));
        setUsername(localStorage.getItem('username'));
    }, []);

    return (
        <Router>
            <Navbar authToken={authToken} setAuthToken={setAuthToken} username={username} />
            <div className="container">
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/auth" element={<Auth setAuthToken={setAuthToken} />} />
                    <Route path="/interests" element={authToken ? <Interests authToken={authToken} setAuthToken={setAuthToken} /> : <Navigate to="/auth" />} />
                    <Route path="/profile" element={authToken ? <Profile authToken={authToken} /> : <Navigate to="/auth" />} />
                    <Route path="/chats" element={authToken ? <Chats authToken={authToken} /> : <Navigate to="/auth" />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
