import React from 'react';
import './styles.scss';

const Homepage = () => {
    return (
        <div className="outer-container">
            <h1>Welcome to LearnMate</h1>
            <p>LearnMate is your personalized learning companion, designed to help you master Computer Science with the power of AI. Whether you're a beginner or an advanced learner, LearnMate tailors its teaching methods to suit your needs and help you achieve your goals.</p>
            <p>Explore a variety of topics, engage with interactive content, and get personalized feedback to accelerate your learning journey. Join us and transform the way you learn computer science.</p>
        </div>
    );
};

export default Homepage;
