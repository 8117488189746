import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { API_BASE_URL } from './config';


const Interests = ({ authToken }) => {
    const [interests, setInterests] = useState([]);
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitError, setSubmitError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!authToken) {
            navigate('/auth');
        } else {
            const fetchInterests = async () => {
                try {
                    const response = await axios.get(`${API_BASE_URL}/api/user/interests`, {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    });
                    setInterests(response.data);
                    setLoading(false);
                } catch (err) {
                    setError(err);
                    setLoading(false);
                }
            };

            fetchInterests();
        }
    }, [authToken, navigate]);

    const toggleInterest = (interestId) => {
        if (selectedInterests.includes(interestId)) {
            setSelectedInterests(selectedInterests.filter(id => id !== interestId));
        } else {
            if (selectedInterests.length >= 4) {
                setSelectedInterests([...selectedInterests.slice(1), interestId]);
            } else {
                setSelectedInterests([...selectedInterests, interestId]);
            }
        }
    };

    const handleSubmit = async () => {
        if (selectedInterests.length < 2) {
            setSubmitError('Please select at least 2 interests.');
            return;
        }
        try {
            await axios.post(`${API_BASE_URL}/api/user/interests`,
                { interests: selectedInterests },
                { headers: { Authorization: `Bearer ${authToken}` } });
            // navigate('/proficiency'); // Navigate to the proficiency assessment page
            navigate('/chats');
        } catch (err) {
            setSubmitError('Failed to save interests');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="interests-container">
            <h1>Choose what you're interested in for a more personalized tutor</h1>
            <div className="interests-grid">
                {interests.map((interest) => (
                    <div
                        key={interest.id}
                        className={`interest-tile ${selectedInterests.includes(interest.id) ? 'selected' : ''}`}
                        onClick={() => toggleInterest(interest.id)}>
                        {interest.name}
                    </div>
                ))}
            </div>
            {submitError && <p className="error">{submitError}</p>}
            <button onClick={handleSubmit}>Submit</button>
        </div>
    );
};

export default Interests;
