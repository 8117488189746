import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import {API_BASE_URL} from "./config";

const Auth = ({ setAuthToken }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = isSignUp ? `${API_BASE_URL}/api/auth/register` : `${API_BASE_URL}/api/auth/login`;
        try {
            const response = await axios.post(url, { username, password });
            if (!isSignUp) {
                const token = response.data.token;
                setAuthToken(token);
                localStorage.setItem('token', token);
                localStorage.setItem('username', username); // Store username

                // Check if the user has interests
                const interestsResponse = await axios.get(`${API_BASE_URL}/api/user/has-interests`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (interestsResponse.data.hasInterests) {
                    navigate('/chats');
                } else {
                    navigate('/interests');
                }
            } else {
                setIsSignUp(false);
            }
            setUsername('');
            setPassword('');
            setError(null);
        } catch (err) {
            setError(err.response.data.error);
        }
    };

    return (
        <div className="auth-container">
            <h1>{isSignUp ? 'Sign Up' : 'Sign In'}</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">{isSignUp ? 'Sign Up' : 'Sign In'}</button>
            </form>
            {error && <p className="error">{error}</p>}
            <div className="auth-actions">
                <button onClick={() => setIsSignUp(!isSignUp)}>
                    {isSignUp ? 'Already have an account? Sign In' : 'New here? Sign Up'}
                </button>
            </div>
        </div>
    );
};

export default Auth;
